import { useTranslation } from "react-i18next";
import { InformationContentImage, QRCode } from "../../styles";
import { GET_LANGUAGE, GET_USER_INFO } from "../../../../../../../util/common";
import {
  WECHAT_ASSISTANT,
  FACEBOOK_LINK_TW,
  FACEBOOK_LINK_US,
  EXPERT_PRODUCTS,
  EXPERT_PRODUCTS_WITHOUT_REPORT,
  GUIDE_PRODUCTS,
  PRODUCTS_WITH_REPORT,
  PRODUCTS,
  PRODUCTS_THAT_CAN_BUY_UP_TO_CPC,
} from "../../../../../../../constants/common";
import weChatQRCode from "../../../assets/weChatQR.png";
import facebookQRCodeTW from "../../../assets/FacebookQRCodeTW.jpeg";
import facebookQRCodeUS from "../../../assets/FacebookQRCodeUS.jpeg";
import penguinSmartLogo from "../../../assets/Penguin.svg";
import {
  IoMailOutline,
  IoTimeOutline,
  IoLogoFacebook,
  IoLogoWechat,
} from "react-icons/io5";
import { FaSms } from "react-icons/fa";
import {
  QuestionText,
  Segment,
  Title,
  EndEvalSegmentText,
  EndEvalSegment,
  EndEvalPicAndTitleContainer,
  EndEvalLogo,
  EndEvalTitle,
  EndEvalSegmentTextContainer,
  EndEvalBigContactsContainer,
  EndEvalContactItemContainer,
  EndEvalContactIcon,
  EndEvalContactContent,
  EndEvalContactLogo,
  EndEvalLink,
  EndEvalSocialMediaLink,
  EndEvalLinkContainer,
  EndEvalButtonContainer,
  EndEvalButton,
} from "components/User/Evaluation/styles";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import api_address from "constants/config";
import ReportReadyModal from "components/common/ReportReadyModal";
const EndEvalPage = (props) => {
  const [product, setProduct] = useState(props.product);
  const [completed, setCompleted] = useState(props.completed);
  const [showReportReadyModal, setShowReportReadyModal] = useState(false);
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("usd");
  const history = useHistory();
  const { t } = useTranslation();
  const language = GET_LANGUAGE() ?? "zh-CN";
  const facebookQRCode =
    language === "zh-TW" ? facebookQRCodeTW : facebookQRCodeUS;
  const facebookLink =
    language === "zh-TW" ? FACEBOOK_LINK_TW : FACEBOOK_LINK_US;
  const handleGuideClick = () => {
    window.open(process.env.REACT_APP_GUIDE_URL as string);
  };
  const handleHistoryClick = () => {
    history.push("/evaluation-history");
  };
  const handleCPCBuyUpClick = () => {
    history.push(
      `/purchases-payment?product=CustomizedPrivateCoachingBuyUp&currency=${currency}&isBuyUp=true`
    );
  };

  const getUserInfo = async () => {
    const userData = await GET_USER_INFO();
    setCountry(userData.country);
  };

  useEffect(() => {
    getUserInfo();
    if (country) {
      if (country == "cn") {
        setCurrency("cny");
      } else if (country == "tw") {
        setCurrency("twd");
      } else {
        setCurrency("usd");
      }
    }
  }, [country]);

  useEffect(() => {
    setCompleted(props.completed);
    setProduct(props.product);
  }, [props.product, props.completed]);
  useEffect(() => {
    if (PRODUCTS_WITH_REPORT.includes(product) && completed) {
      setShowReportReadyModal(true);
    } else {
      setShowReportReadyModal(false);
    }
  }, [completed, product]);
  const EndEvalTitleText = () => {
    switch (product) {
      case PRODUCTS.PEAWithReport:
        return t("user.survey.corePro.waiting.EndEvalTitlePEAWithReport");
      case PRODUCTS.CPCWithReport:
        return t("user.survey.corePro.waiting.EndEvalTitleCPCWithReport");
      case PRODUCTS.CPC:
        return t("user.survey.corePro.waiting.EndEvalTitleCPC");
      case PRODUCTS.CPCBuyUp:
        return t("user.survey.corePro.waiting.EndEvalTitleCPCWithReport");
      case PRODUCTS.CPCTrial:
        return t("user.survey.corePro.waiting.EndEvalTitleCPCTrial");
      case PRODUCTS.CPCBH:
        return t("user.survey.corePro.waiting.EndEvalTitleCPCBH");
      case PRODUCTS.PEA:
        return t("user.survey.corePro.waiting.EndEvalTitlePEA");
      case PRODUCTS.Core:
        return t("user.survey.corePro.waiting.EndEvalTitleCore");
      case PRODUCTS.CorePro:
        return t("user.survey.corePro.waiting.EndEvalTitleCorePro");
      case PRODUCTS.CPCFlex:
        return t("user.survey.corePro.waiting.EndEvalTitleCPCFlex");
      case PRODUCTS.CPCFlexWithReport:
        return t("user.survey.corePro.waiting.EndEvalTitleCPCFlexWithReport");
      case PRODUCTS.SpecializedAssessment:
        return t("user.survey.corePro.waiting.EndEvalTitleDefault");
      case PRODUCTS.CPCTeleHealth:
        return t("user.survey.corePro.waiting.EndEvalTitleDefault");
      default:
        return t("user.survey.corePro.waiting.EndEvalTitleDefault");
    }
  };
  const EndEvalSubtitleText = () => {
    if (
      product === PRODUCTS.PEAWithReport ||
      product === PRODUCTS.CPCWithReport
    ) {
      return t("user.survey.corePro.waiting.EndEvalSubtitle1");
    } else if (product === PRODUCTS.CorePro) {
      return t("user.survey.corePro.waiting.EndEvalSubtitle1Legacy");
    } else {
      return t("user.survey.corePro.waiting.EndEvalSubtitle1NoReport");
    }
  };
  const onGoToReport = () => {
    history.push("/evaluation-history");
  };
  return (
    <>
      <ReportReadyModal
        onGoToReport={onGoToReport}
        display={showReportReadyModal}
      />
      <EndEvalSegment>
        <EndEvalPicAndTitleContainer>
          <EndEvalLogo src={penguinSmartLogo} />
          <EndEvalTitle>{EndEvalTitleText()}</EndEvalTitle>
        </EndEvalPicAndTitleContainer>
        <EndEvalSegmentTextContainer>
          <EndEvalSegmentText>{EndEvalSubtitleText()}</EndEvalSegmentText>
          <EndEvalSegmentText>
            {t("user.survey.corePro.waiting.EndEvalSubtitle2")}
          </EndEvalSegmentText>
        </EndEvalSegmentTextContainer>
        <EndEvalBigContactsContainer>
          <EndEvalContactItemContainer>
            <IoMailOutline size={"2.5rem"} style={{ marginTop: "1px" }} />
            <EndEvalContactContent>
              <EndEvalLink href="mailto:support@mypenguinsmart.com">
                support@mypenguinsmart.com
              </EndEvalLink>
            </EndEvalContactContent>
          </EndEvalContactItemContainer>
          <EndEvalContactItemContainer>
            {!(language === "zh-CN") ? (
              <>
                <IoLogoFacebook
                  size={"2.5rem"}
                  color="#1877F2"
                  style={{ marginTop: "1px", color: "#44474E" }}
                />
                <EndEvalContactContent>
                  <EndEvalLink href={facebookLink} target="_blank">
                    {t("user.survey.corePro.waiting.EndEvalPenguinSmart")}
                  </EndEvalLink>
                </EndEvalContactContent>
              </>
            ) : (
              <>
                <IoLogoWechat
                  size={"2.5rem"}
                  color="#44474E"
                  style={{ marginTop: "1px" }}
                />
                <EndEvalContactContent>
                  <EndEvalLink>{WECHAT_ASSISTANT}</EndEvalLink>
                </EndEvalContactContent>
              </>
            )}
          </EndEvalContactItemContainer>
          {country == "us" ? (
            <EndEvalContactItemContainer>
              <FaSms size={"2.5rem"} style={{ marginTop: "1px" }} />
              <EndEvalContactContent>
                <EndEvalLink href="sms:16503341899">
                  {"+1 (650) 334 1899"}
                </EndEvalLink>
              </EndEvalContactContent>
            </EndEvalContactItemContainer>
          ) : null}
        </EndEvalBigContactsContainer>
        <EndEvalButtonContainer>
          <EndEvalButton background="#6898E3" onClick={handleHistoryClick}>
            {t("user.survey.corePro.waiting.EndEvalEvaluationHistory")}
          </EndEvalButton>
          {GUIDE_PRODUCTS.includes(product) && (
            <EndEvalButton background="#5ABAAE" onClick={handleGuideClick}>
              {t(`user.purchase.order.product.${product}.title`)}
            </EndEvalButton>
          )}
        </EndEvalButtonContainer>
      </EndEvalSegment>
    </>
  );
};
export default EndEvalPage;
