import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { withRouter, match, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import api_address from "../../../../../constants/config";
import { PRODUCTS, EVAL_STEPS } from "../../../../../constants/common";
import EvalProgressBar from "../../ProgressBar/EvalProgressBar";
import NotificationModal from "../../../../common/NotificationModal";
import Waiting from "./WaitingPage";
import Reviewing from "./ReviewingPage";
import ExpertReport from "./ResultPage/ExpertReport";
import CoreProResult from "./ResultPage/CoreProResult";
import CPCResult from "./ResultPage/CPCResult";
import EndEvalPage from "./ResultPage/EndEvalPage";
import { Background, Container } from "../../styles";
import { GET_LANGUAGE } from "util/common";

interface WaitingSectionProps {
  match?: match<Match>;
  location: History;
}

interface Match {
  transactionId: any;
}

interface WaitingSectionState {
  [key: string]: boolean | string;
  active: boolean;
  id: string;
  resultId: string;
  product: string;
  transactionId: string;
  currentStep: string;
  therapistUUID: string;
  completed: boolean;
  consultationLink: string;
  consultationTime: string;
  weChatId: string;
  facebookId: string;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
}

type Props = WaitingSectionProps & WithTranslation & RouteComponentProps;

class WaitingSection extends React.Component<Props, WaitingSectionState> {
  _isMounted: boolean;
  constructor(props: Props) {
    super(props);
    this.state = {
      active: false,
      id: "",
      resultId: "",
      product: "",
      transactionId: "",
      currentStep: "",
      therapistUUID: "",
      completed: false,
      consultationLink: "",
      consultationTime: "",
      weChatId: "",
      facebookId: "",
      message: "",
      showModal: false,
      notificationStatus: false,
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    const token = Cookies.get("token");
    const { transactionId } = this.props.match.params;

    try {
      const res = await axios.get(
        api_address + "api/results/" + transactionId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { authData, result, status } = res.data;
      if (!status) {
        throw new Error(res.data.message);
      }

      const { id } = authData;

      const {
        product,
        currentstep,
        therapistUUID,
        completed,
        consultationLink,
        consultationTime,
        weChatId,
        facebookId,
      } = result;

      const date = new Date(consultationTime);
      const consultationDateTime = consultationTime
        ? date.getFullYear() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getDate() +
          "\n" +
          date.getHours() +
          ":" +
          date.getMinutes()
        : "";
      this.setState({
        id: id,
        resultId: result.id,
        transactionId: transactionId,
        product: product,
        therapistUUID,
        completed,
        consultationLink,
        consultationTime: consultationDateTime,
        currentStep: currentstep,
        weChatId: weChatId || "",
        facebookId: facebookId || "",
      });
      window.scroll(0, 0);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  resultPageComponent = () => {
    const { product, completed } = this.state;

    const productsUsingEndEvalPage = [
      PRODUCTS.Core,
      PRODUCTS.CorePro,
      PRODUCTS.CPCWithReport,
      PRODUCTS.CPCBuyUp,
      PRODUCTS.CPC_Post,
      PRODUCTS.PEAWithReport,
      PRODUCTS.PEA_Post,
      PRODUCTS.CPC,
      PRODUCTS.PEA,
      PRODUCTS.CoreProBuyUp,
      PRODUCTS.CPCFlex,
      PRODUCTS.CPCFlexWithReport,
      PRODUCTS.SpecializedAssessment,
      PRODUCTS.CPCTeleHealth,
    ];

    if (product === PRODUCTS.ExpertEval) {
      return <ExpertReport handleGoToReport={this.handleGoToReport} />;
    }

    if (productsUsingEndEvalPage.includes(product)) {
      return <EndEvalPage product={product} completed={completed} />;
    }

    return <div>Product does not exist</div>;
  };

  handleGoToReport = () => {
    const { resultId } = this.state;
    window.open("/core-pro-report/" + resultId);
  };

  render = () => {
    const { product, showModal, message, notificationStatus, currentStep } =
      this.state;

    const step =
      currentStep === EVAL_STEPS.report
        ? EVAL_STEPS.consultation
        : EVAL_STEPS.expertAnalysis;
    return (
      <Background>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <EvalProgressBar product={product} step={step} />
        <Container>{this.resultPageComponent()}</Container>
      </Background>
    );
  };
}

export default withRouter(withTranslation()(WaitingSection));
